// src/components/UserMainLogin.jsx

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

function UserMainLogin({ onLoginSuccess }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    setError('');
    setMessage('');

    let bodyContent = JSON.stringify({ email, password });

    try {
      let response = await fetch('/api/users/login', {
        method: 'POST',
        body: bodyContent,
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json'
        },
        credentials: 'include' // Important for cookie handling
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('Login successful!');
        onLoginSuccess(data.user); // Pass user data to App for state management
        navigate('/home'); // Redirect user after successful login
      } else {
        setError(data.message || 'Incorrect details, please try again.');
      }
    } catch (networkError) {
      setError('Failed to connect to the server');
    }
  };

  return (
    <div className="container my-4">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title mb-4 text-center">Login</h2>
              {error && <p className="text-danger">{error}</p>}
              {message && <p className="text-success">{message}</p>}
              <form onSubmit={handleLogin}>
                <div className="mb-3">
                  <label htmlFor="login-email" className="form-label">
                    Email:
                  </label>
                  <input
                    type="email"
                    id="login-email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="login-password" className="form-label">
                    Password:
                  </label>
                  <input
                    type="password"
                    id="login-password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password"
                    required
                  />
                </div>
                <div className="mb-3">
                  <Link to="/forgot-password">Forgot Password?</Link>
                </div>
                <button type="submit" className="btn btn-primary w-100">
                  Login
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserMainLogin;
