import { Link } from 'react-router-dom';
import React from 'react';

function PasswordReset() {
  return (
    <div className="container my-4">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div className="card text-center">
            <div className="card-body">
              <h2 className="card-title mb-4">Password Successfully Updated</h2>
              <p className="mb-4">
                Your password has been successfully reset. You can now log in with your new password.
              </p>
              <p className="mb-4">
                If you experience any issues, please contact our support team for assistance.
              </p>
              <Link to="/login" className="btn btn-primary">
                Go to Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordReset;
