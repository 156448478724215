import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import styles from "./Contact.module.css";

const DigitalBlendContact = () => {
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    mobile: '',
    email: '',
    subject: ''
  });

  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/users/contact', formData);

      // Redirect to the ContactFormSuccess component upon successful submission
      if (response.status === 200) {
        navigate('/contact-success');
      } else {
        alert('Failed to send message. Please try again.');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message. Please try again.');
    }
  };

  return (
    <div className={styles.contactContainer}>
      {/* Hero section with background image */}
      <section className="hero-section">
        <div className="hero-content">
          <h1 className="orbitron-header">
            CONT<span className="yellow-text">ACT</span>
          </h1>
        </div>
      </section>

      {/* Sub-header section */}
      <section className={styles.contactHeader}>
        <h2>CONTACT</h2>
        <p>Reach out to us at Digital Blend for any inquiries or support.</p>
      </section>

      {/* Main contact form section */}
      <form className={styles.form} onSubmit={handleSubmit}>
        {/* Row 1: Name and Surname */}
        <div className={styles.row}>
          <div className={styles.formGroup}>
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="surname">Surname</label>
            <input type="text" id="surname" name="surname" value={formData.surname} onChange={handleChange} required />
          </div>
        </div>

        {/* Row 2: Mobile Contact and Email */}
        <div className={styles.row}>
          <div className={styles.formGroup}>
            <label htmlFor="mobile">Mobile Contact</label>
            <input type="text" id="mobile" name="mobile" value={formData.mobile} onChange={handleChange} required />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
          </div>
        </div>

        {/* Row 3: Subject */}
        <div className={styles.row}>
          <div className={styles.formGroup}>
            <label htmlFor="subject">Subject</label>
            <input type="text" id="subject" name="subject" value={formData.subject} onChange={handleChange} required />
          </div>
        </div>

        <button type="submit" className={styles.submitButton}>
          Submit
        </button>
      </form>
    </div>
  );
};

export default DigitalBlendContact;
