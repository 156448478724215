import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Nav, Container, NavItem, NavDropdown } from "react-bootstrap";

function DigitalBlendNav({ user, onLogout }) {
  const [expanded, setExpanded] = useState(false);

  const closeMenu = () => setExpanded(false);

  return (
    <Navbar
      collapseOnSelect
      expanded={expanded}
      expand="lg"
      bg="light"  // Use a valid Bootstrap background color
      variant="light"
    >
      <Container>
        {/* Branding with Logo */}
        <Navbar.Brand as={NavLink} to="/home" onClick={closeMenu}>
          <div className="d-flex justify-content-center">
            <img
              src="/DigitalBlendLogo.png"
              alt="Digital Blend Logo"
              className="img-fluid"
              style={{
                maxWidth: "4rem",
                paddingTop: "1%",
                paddingBottom: "1%",
              }}
            />
          </div>
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => setExpanded(expanded ? false : true)}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {/* Home Link */}
            <NavItem>
              <Nav.Link
                as={NavLink}
                to="/home"
                onClick={closeMenu}
                className="text-dark"
              >
                HOME
              </Nav.Link>
            </NavItem>
            {/* Clients Link */}
            <NavItem>
              <Nav.Link
                as={NavLink}
                to="/clients"
                onClick={closeMenu}
                className="text-dark"
              >
                CLIENTS
              </Nav.Link>
            </NavItem>
            {/* Contact Link */}
            <NavItem>
              <Nav.Link as={NavLink} to="/contact" onClick={closeMenu}>
                CONTACT
              </Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link as={NavLink} to="/weather" onClick={closeMenu}>
                WEATHER
              </Nav.Link>
            </NavItem>
          </Nav>
          <Nav>
            <NavDropdown
              title={user ? user.name : "Account"} // Display user's name if logged in
              id="collasible-nav-dropdown"
              alignright="true"
            >
              {user ? (
                <>
                  {/* Profile */}
                  <NavDropdown.Item as={NavLink} to="/profile" onClick={closeMenu}>
                    Profile
                  </NavDropdown.Item>
                  {/* Settings */}
                  <NavDropdown.Item as={NavLink} to="/forgot-password" onClick={closeMenu}>
                    Reset Password
                  </NavDropdown.Item>
                  {/* Logout */}
                  <NavDropdown.Item as={NavLink} to="/home" onClick={() => { onLogout(); closeMenu(); }}>
                    Logout
                  </NavDropdown.Item>
                </>
              ) : (
                <>
                  {/* Register */}
                  <NavDropdown.Item as={NavLink} to="/register" onClick={closeMenu}>
                    Register
                  </NavDropdown.Item>
                  {/* Login */}
                  <NavDropdown.Item as={NavLink} to="/login" onClick={closeMenu}>
                    Login
                  </NavDropdown.Item>
                </>
              )}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default DigitalBlendNav;
