import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import styles from './Home.module.css';

const DigitalBlendHome = () => {
  return (
    <div className={styles.homeContainer}>
      {/* Parent container */}
      {/* Hero section with background image */}
      <section className="hero-section">
        <div className="hero-content">
          <h1 className="orbitron-header">
            DIGITAL <span className="yellow-text">BLEND</span>
          </h1>
        </div>
      </section>
      {/* Sub-header section */}
      <section className={styles.subHeader}>{/* <h2>WELCOME</h2> */}</section>
      {/* Main content section */}
      {/* Header Intro */}
      <main className={styles.mainContent}>
        <article className={styles.portfolioItems}>
          <section className={styles.homeHeading}>
          <h3>NEED A WEBSITE?</h3>
          </section>
          <p className={styles.paragraphStyle}>
            At Digital Blend, we build websites and digital applications that
            help them with their lives. We love working with Wordpress, Shopify
            and also building custom web applications, like this one.
          </p>
        </article>
        {/* First List DIV */}

        {/* First Section - WORDPRESS*/}
        {/* <article className="portfolio-items"> */}
          <article className={styles.portfolioItems}>
          <div className={styles.headerWithThumbnail}>
        <img src="/media/wp_logo.png" alt="WordPress logo" className={styles.thumbnail} />
        <h4>WORDPRESS</h4>
      </div>
            <p className={styles.paragraphStyle}>
              The world's most popular content delivery network, CDN, offers an
              amazing offer for clients who want efficiency, security, and a
              simpler approach to their web presence. With a plethora of 3rd
              party plug-ins and themes, WordPress is the obvious choice for
              most companies for versatility, reduced production time, relative
              ease-of-use as well as boasting an impressive e-commerce
              functionality with WooCommerce, conveniently built by the same
              people responsible for WordPress itself.
            </p>
            {/* wordpress accordian */}
            <div className="accordion" id="wordpressAccordian">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    WORDPRESS FEATURES
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#whatWeDoAccordion"
                >
                  <div className="accordion-body">
                    <ul>
                      <li>
                        Harnessing the power of WordPress to create stunning,
                        responsive websites that are easy to manage and update.
                      </li>
                      <li>
                        Relativley quick turn-around time from concept to
                        completion.
                      </li>
                      <li>Fully e-commerce compatible with woocommerce</li>
                      <li>
                        Multitiude of pre-built styles and templates to choose
                        from using Themify's or Divi, or any other theme that
                        you like<br></br>
                        for super quick build times
                      </li>
                      <li>Safe and secure robust mySQL database</li>
                      <li>
                        Array of plugins to assist with everything from
                        structuring forms, setting up email, anti-spam and SEO
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </article>

          {/* Second Section - Shopify & Ecwid */}
          <article className={styles.portfolioItems}>
          <div className={styles.headerWithThumbnail}>
        <img src="/media/shopify_logo.png" alt="Shopify logo" className={styles.thumbnail} />
        <h4>SHOPIFY & ECWID</h4>
      </div>
            <p className={styles.paragraphStyle}>
              At Digital Blend, we understand the importance of a powerful and
              seamless online store. That's why we offer specialized services
              for Ecwid and Shopify, two of the leading e-commerce platforms.
            </p>
            <br />
            {/* ECWID Section */}
            <h4>Ecwid Integration and Customization</h4>
            <p className={styles.paragraphStyle}>
              Ecwid is a flexible and user-friendly e-commerce solution that can
              be integrated into any website. Our services include:
            </p>
            {/* ECWID Accordian */}
            <div className="accordion" id="ecwidAccordion">
              <div className="accordion-item">
                <h2 className="accordion-header" id="ecwidHeadingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#ecwidCollapseOne"
                    aria-expanded="false"
                    aria-controls="ecwidCollapseOne"
                  >
                    ECWID FEATURES
                  </button>
                </h2>
                <div
                  id="ecwidCollapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="ecwidHeadingOne"
                  data-bs-parent="#ecwidAccordion"
                >
                  <div className="accordion-body">
                    <ul>
                      <li><span style={{fontWeight: "bold"}}>Seamless Integration:</span> Effortlessly add an online store to your existing site.</li>
                      <li><span style={{fontWeight: "bold"}}>Customization:</span> Tailor the look and feel of your store to match your brand identity.</li>
                      <li><span style={{fontWeight: "bold"}}>Product Management:</span> Simplify the process of adding, editing, and managing your products.</li>
                      <li><span style={{fontWeight: "bold"}}>Responsive Design:</span> Ensure your store looks great and functions perfectly on any device. </li>
                    </ul>         
                  </div>
                </div>
              </div>
            </div>

            {/* SHOPIFY SECTION*/}            <br />
            <h4>Shopify Development and Optimization</h4>
            <p className={styles.paragraphStyle}>
              Shopify is a robust e-commerce platform designed for businesses of
              all sizes. Our expertise in Shopify includes:
            </p>
            {/* SHOPIFY Accordian */}
            <div className="accordion" id="shopifyAccordion">
              <div className="accordion-item">
                <h2 className="accordion-header" id="shopifyHeadingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#shopifyCollapseOne"
                    aria-expanded="false"
                    aria-controls="shopifyCollapseOne"
                  >
                    SHOPIFY FEATURES
                  </button>
                </h2>
                <div
                  id="shopifyCollapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="shopifyHeadingOne"
                  data-bs-parent="#shopifyAccordion"
                >
                  <div className="accordion-body">
                    <ul>
                      <li><span style={{fontWeight: "bold"}}>Custom Store Development: </span>Build a unique online store from scratch, tailored to your
                      business needs.</li>
                      <li><span style={{fontWeight: "bold"}}>Theme Customization: </span>Personalize your Shopify store with custom themes and design
                      elements. </li>
                      <li><span style={{fontWeight: "bold"}}>App Integration: </span>Enhance your store's functionality with powerful Shopify
                      apps. </li>
                      <li><span style={{fontWeight: "bold"}}>SEO and Performance Optimization: </span>Improve your store's visibility and speed for a better user
                      experience and higher search engine rankings. </li>
                    </ul>                 
                  </div>
                </div>
              </div>
            </div>
          </article>
          <br />
        {/* </article> */}

         {/* THIRD SECTION - MERN */}

        <article className={styles.portfolioItems}>
        <div className={styles.headerWithThumbnail}>
        <img src="/media/react_logo.png" alt="react logo" className={styles.thumbnail} />
        <h4>CUSTOM REACT APPLICATION</h4>
      </div>
          <p className={styles.paragraphStyle}>
            Powerful, safe, and super fast. React, Node, Express, and MongoDB
            stack offer limitless opportunities Perfect user experience on all
            devices, built with 'mobile-first' sensibility to ensure
            compatibility, speed, and lightweight performance. Paired with
            MongoDB's versatility and scalability, makes setting your database
            simple and secure. React also offers the opportunity to incorporate
            server-side rendering with Next.js for lightning-fast interaction.
            Professional, SEO optimized, and fast, just like this site.
          </p>
          {/* MERN Accordian */} 
          <div className="accordion" id="mernAccordion">
              <div className="accordion-item">
                <h2 className="accordion-header" id="mernHeadingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#mernCollapseOne"
                    aria-expanded="false"
                    aria-controls="mernCollapseOne"
                  >
                    M.E.R.N FEATURES
                  </button>
                </h2>
                <div
                  id="mernCollapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="mernHeadingOne"
                  data-bs-parent="#mernAccordion"
                >
                  <div className="accordion-body">
                    <ul>
                      <li><span style={{fontWeight: "bold"}}>User friendly:</span> Responsive, super-fast with unlimited creative options for user interaction.</li>
                      <li><span style={{fontWeight: "bold"}}>Robust Back-End:</span> With Expresss and Node.js as the foundation, the platform can handle multiple users safely<br></br>
                      without compromising perfromance.</li>
                      <li><span style={{fontWeight: "bold"}}>Secure: </span>Middleware, JWT, Data Validation and secure storage of sensitive data, make MERN a secure option.</li>
                      <li><span style={{fontWeight: "bold"}}>Powerful Database: </span>Using MongoDB, a leading NoSQL database, your application can handle large volumes of data efficiently and quickly. </li>
                      <li><span style={{fontWeight: "bold"}}>SEO and Performance Optimization: </span>Improve your store's visibility and speed for a better user
                      experience and higher search engine rankings. </li>
                      <li><span style={{fontWeight: "bold"}}>Global Support: </span>Built with open-source technologies, MERN applications reduce licensing costs, making it a budget-friendly option 
                          and allowing for faster build times and ease of deployment. 
                      </li>
                    </ul>              
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="end-article">
        <p className={styles.paragraphStyle}>
            Explore our portfolio to see how we've helped our clients achieve
            their digital goals. Let Digital Blend blend creativity and
            technology to elevate your online presence.
          </p>
        </article>

        {/* Add more portfolio items as needed */}
      </main>
    </div>
  );
};
export default DigitalBlendHome;