import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

function ResetPassword() {
  const { resetToken } = useParams(); // Get the token from the URL
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    setError('');
    setMessage('');

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    // Ensure that the token is sent in the request body
    const bodyContent = JSON.stringify({ token: resetToken, newPassword });

    try {
      const response = await fetch('/api/email/reset-password', {
        method: 'POST',
        body: bodyContent,
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('Your password has been successfully reset.');
        navigate('/password-reset');
      } else {
        setError(data.message || 'Failed to reset password.');
      }
    } catch (networkError) {
      setError('Failed to connect to the server.');
    }
  };

  return (
    <div className="container my-4">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title mb-4 text-center">Reset Password</h2>
              {error && <p className="text-danger">{error}</p>}
              {message && <p className="text-success">{message}</p>}
              <form onSubmit={handlePasswordReset}>
                <div className="mb-3">
                  <label htmlFor="new-password" className="form-label">
                    New Password:
                  </label>
                  <input
                    type="password"
                    id="new-password"
                    className="form-control"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="Enter your new password"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="confirm-password" className="form-label">
                    Confirm New Password:
                  </label>
                  <input
                    type="password"
                    id="confirm-password"
                    className="form-control"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm your new password"
                    required
                  />
                </div>
                <button type="submit" className="btn btn-primary w-100">
                  Reset Password
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
