import React from 'react';

const FooterPolicy = () => {
  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-12">
          <h5 className="text-center">Terms & Conditions</h5>
          <p className="text-muted">
            Welcome to Digital Blend. These terms and conditions outline the rules and regulations for the use of our website and services. By accessing this website, we assume you accept these terms and conditions in full. Do not continue to use Digital Blend’s website if you do not accept all of the terms and conditions stated on this page.
          </p>
          <p className="text-muted">
            The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice, and any or all Agreements: "Client", "You" and "Your" refer to you, the person accessing this website and accepting the Company's terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refer to our Company. "Party", "Parties", or "Us", refer to both the Client and ourselves, or either the Client or ourselves.
          </p>
          <p className="text-muted">
            All terms refer to the offer, acceptance, and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner, whether by formal meetings of a fixed duration, or any other means, for the express purpose of meeting the Client’s needs in respect of the provision of the Company’s stated services/products, in accordance with and subject to, prevailing law of the country of operation. Any use of the above terminology or other words in the singular, plural, capitalization, and/or he/she or they, are taken as interchangeable and therefore as referring to the same.
          </p>
          <p className="text-muted">
            We employ the use of cookies. By using Digital Blend's website, you consent to the use of cookies in accordance with Digital Blend’s privacy policy. Most modern-day interactive websites use cookies to enable us to retrieve user details for each visit. Cookies are used in some areas of our site to enable the functionality of this area and ease of use for those people visiting.
          </p>
          <p className="text-muted">
            Unless otherwise stated, Digital Blend and/or its licensors own the intellectual property rights for all material on Digital Blend. All intellectual property rights are reserved. You may view and/or print pages from Digital Blend for your own personal use subject to restrictions set in these terms and conditions.
          </p>
          <p className="text-muted">
            You must not:
          </p>
          <ul className="text-muted">
            <li>Republish material from Digital Blend</li>
            <li>Sell, rent or sub-license material from Digital Blend</li>
            <li>Reproduce, duplicate or copy material from Digital Blend</li>
            <li>Redistribute content from Digital Blend (unless content is specifically made for redistribution)</li>
          </ul>
          <p className="text-muted">
            This Agreement shall begin on the date hereof.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FooterPolicy;
