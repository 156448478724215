import { Link } from 'react-router-dom';
import React from 'react';

function ContactFormSuccess() {
  return (
    <div className="container my-4">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div className="card text-center">
            <div className="card-body">
              <h2 className="card-title mb-4">Message Sent Successfully</h2>
              <p className="mb-4">
                We will be in touch shortly
              </p>
              <p className="mb-4">
                If you experience any issues, please contact our support team for assistance.
              </p>
              <Link to="/home" className="btn btn-primary">
                Back Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactFormSuccess;
