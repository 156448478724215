import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from "./Weather.modules.css";

const WeatherComponent = () => {
  const [dailyWeather, setDailyWeather] = useState({});
  const [locationError, setLocationError] = useState(null);

  useEffect(() => {
    // Check if geolocation is available
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          try {
            const response = await axios.get('/api/users/weather', {
              params: { latitude, longitude }
            });
            const groupedData = groupDataByDay(response.data);
            setDailyWeather(groupedData);
          } catch (error) {
            console.error('Error fetching weather data:', error);
          }
        },
        (error) => {
          setLocationError('Failed to retrieve location');
          console.error('Geolocation error:', error);
        }
      );
    } else {
      setLocationError('Geolocation is not supported by this browser.');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Convert degrees to compass direction
  const getCompassDirection = (angle) => {
    const directions = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'];
    return directions[Math.round(angle / 22.5) % 16];
  };

  // Determine if the time is day or night
  const isDaytime = (hour) => {
    return hour >= 6 && hour < 18; // Consider daytime between 6 AM and 6 PM
  };

  // Group data by day
  const groupDataByDay = (data) => {
    const days = {};
    data.times.forEach((time, index) => {
      const date = new Date(time);
      const dateString = date.toLocaleDateString(undefined, {
        weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
      });
      const hour = date.getHours(); // Get the hour in 24-hour format
      const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

      if (!days[dateString]) {
        days[dateString] = [];
      }

      days[dateString].push({
        time: timeString,
        isDay: isDaytime(hour),
        temperature: data.temperatures[index],
        apparentTemperature: data.apparentTemperatures[index],
        precipitation: data.rains[index],
        weatherCode: data.weatherCodes[index],  // New addition
        cloudCover: data.cloudCover[index],
        windSpeed: data.windSpeeds.w10m[index],
        windDirection: getCompassDirection(data.windDirections.wd10m[index]),
        windGust: data.windGusts[index],
        weatherIcon: determineWeatherIcon(
          isDaytime(hour),
          data.weatherCodes[index]  // Pass the weather code
        )
      });
    });

    return days;
  };

  // Function to determine weather icon based on the weather code and time of day
  const determineWeatherIcon = (isDay, weatherCode) => {
    const weatherIcons = {
      0: isDay ? "☀️" : "🌙",  // Clear sky
      1: isDay ? "⛅" : "🌙☁️", // Mainly clear
      2: "☁️", // Partly cloudy
      3: "☁️", // Overcast
      45: "🌫️", // Fog
      48: "🌫️", // Depositing rime fog
      51: "🌧️", // Drizzle: Light
      53: "🌧️", // Drizzle: Moderate
      55: "🌧️🌧️", // Drizzle: Dense intensity
      56: "🌧️", // Freezing Drizzle: Light
      57: "🌧️", // Freezing Drizzle: Dense intensity
      61: "🌧️", // Rain: Slight
      63: "🌧️🌧️", // Rain: Moderate
      65: "🌧️🌧️🌧️", // Rain: Heavy intensity
      66: "🌧️", // Freezing Rain: Light
      67: "🌧️🌧️", // Freezing Rain: Heavy intensity
      71: "❄️", // Snow fall: Slight
      73: "❄️❄️", // Snow fall: Moderate
      75: "❄️❄️❄️", // Snow fall: Heavy intensity
      77: "❄️", // Snow grains
      80: "🌧️", // Rain showers: Slight
      81: "🌧️🌧️", // Rain showers: Moderate
      82: "🌧️🌧️🌧️", // Rain showers: Violent
      85: "❄️", // Snow showers slight
      86: "❄️❄️", // Snow showers heavy
      95: isDay ? "⛈️" : "🌩️", // Thunderstorm: Slight or moderate
      96: isDay ? "⛈️" : "🌩️", // Thunderstorm with slight hail
      99: isDay ? "⛈️" : "🌩️", // Thunderstorm with heavy hail
    };

    return weatherIcons[weatherCode] || "❓"; // Default to a question mark for unknown codes
  };

  if (locationError) {
    return <p>{locationError}</p>;
  }

  if (!dailyWeather || Object.keys(dailyWeather).length === 0) {
    return <p>Loading weather data...</p>;
  }

  return (
    <div className={styles.clientsContainer}>
    <section className="hero-section">
      <div className="hero-content">
        <h1 className="orbitron-header">
          WEA<span className="yellow-text">THER</span>
        </h1>
      </div>
    </section>
    <div className="container dark-mode">
      <h2 className="my-3">7 Day Weather Forecast</h2>
      {Object.entries(dailyWeather).map(([date, hours], dayIdx) => (
        <div key={dayIdx} className="mb-4">
          <h3>{date}</h3>
          <table className="table table-bordered table-dark">
            <thead>
              <tr>
                <th scope="col">Time</th>
                <th scope="col">Weather</th>
                <th scope="col">Temp.</th>
                <th scope="col">Rain - mm</th>
                <th scope="col">Wind Direction</th>
                <th scope="col">Wind speed km/h</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(hours) && hours.map((hourData, idx) => (
                <tr key={idx}>
                  <td>{hourData.time}</td>
                  <td>{hourData.weatherIcon}</td>
                  <td>{hourData.temperature}°</td>
                  <td>{hourData.precipitation}</td>
                  <td>{hourData.windDirection}</td>
                  <td>{hourData.windSpeed}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
    </div>
  );
};

export default WeatherComponent;
