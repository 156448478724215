import { Link } from 'react-router-dom';
import React from 'react';

function GoToMail() {
  return (
    <div className="container my-4">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div className="card text-center">
            <div className="card-body">
              <h2 className="card-title mb-4">Check Your Email</h2>
              <p className="mb-4">
                We've sent a password reset link to your email address. Please check your inbox and follow the instructions to reset your password.
              </p>
              <p className="mb-4">
                If you don't see the email, please check your spam folder or request a new reset link.
              </p>
              <Link to="/home" className="btn btn-primary">
                Return to Homepage
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GoToMail;
