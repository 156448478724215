import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// Main Pages
import DigitalBlendHome from './pages/Home/DigitalBlendHome';
import DigitalBlendClients from './pages/Clients/DigitalBlendClients';
import DigitalBlendContact from './pages/Contact/DigitalBlendContact';
// Nav
import DigitalBlendNav from './pages/DigitalBlendNav';
// Register
import UserRegistration from './RegisterLogin/userRegistration';
// Login
import UserMainLogin from './RegisterLogin/userMainLogin';
// Reset Password Request
import ResetPasswordRequest from './RegisterLogin/resetPasswordRequest';
//Reset and create new password
import ResetPassword from './RegisterLogin/resetPassword';
//GoToMailTemp
import GoToMail from './pages/Temp/GoToMail';
//Password Success Temp Page
import PasswordReset from './pages/Temp/PasswordReset';
//Contact Form Success
import ContactFormSuccess from './pages/Temp/ContactFormSuccess';
//View Profile
import UserProfile from './pages/UserProfile/UserProfile';
//Weather
import WeatherComponent from './pages/Weather/WeatherComponent';
//Neil CV
import Neil_CV from './pages/Resume/Neil_CV';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './style.css';
import FooterPolicy from './pages/Disclaimers.js/FooterPolicy';

function App() {
  const [user, setUser] = useState(null);

  const handleLoginSuccess = (userData) => {
    setUser(userData);
  };

  const handleLogout = () => {
    setUser(null);
    // Additional logout logic (e.g., clearing cookies) can go here
  };

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <DigitalBlendNav user={user} onLogout={handleLogout} />
        </header>
        <main className="App-main">
          <Routes>
            <Route path="/home" element={<DigitalBlendHome />} />
            <Route path="/clients" element={<DigitalBlendClients />} />
            <Route path="/contact" element={<DigitalBlendContact />} />
            <Route path="/register" element={<UserRegistration />} />
            <Route path="/forgot-password" element={<ResetPasswordRequest />} />
            <Route path="/reset-password/:resetToken" element={<ResetPassword />} />
            <Route path="/go-to-mail" element={<GoToMail />} />
            <Route path="/password-reset" element={<PasswordReset />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/weather" element={<WeatherComponent />} />
            <Route path="/contact-success" element={<ContactFormSuccess />} />
            <Route path="/privacy-policy" element={<FooterPolicy />} />
            <Route path="/neil-cv" element={<Neil_CV />} />


            <Route path="/login" element={<UserMainLogin onLoginSuccess={handleLoginSuccess} />} />
            <Route path="*" element={<DigitalBlendHome />} />
          </Routes>
        </main>
        <footer className="App-footer">
          <div className="footer-column">
            <img src="/DigitalBlendLogo.png" alt="Digital Blend Logo" className="footer-logo" />
          </div>
          <div className="footer-column">
            <div>
              <p>mobile: +27 74 175 9591</p>
              <p>email: info@digitalblend.co.za</p>
            </div>
          </div>
          <div className="footer-column">
            <div>

            <Link to="/privacy-policy">
              <p>privacy policy</p>
              </Link>
              {/* <p>cookie policy</p> */}
            </div>
          </div>
        </footer>
        <div className="end-footer-row">
          <div>
            <p className="copywrite-copy">&copy; 2024 Digital Blend All Rights Reserved</p>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
