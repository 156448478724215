import React from "react";
import styles from "./Clients.module.css";

const DigitalBlendClients = () => {
  return (
    <div className={styles.clientsContainer}>
      {" "}
      {/* Parent container */}
      {/* Hero section with background image */}
      <section className="hero-section">
        <div className="hero-content">
          <h1 className="orbitron-header">
            PORT<span className="yellow-text">FOLIO</span>
          </h1>
        </div>
      </section>
      {/* Sub-header section */}
      <section className={styles.clientSubHeader}>
        <h2>CLIENT SITES</h2>
        <p>A short portfolio of our work</p>
      </section>
      {/* Main content section */}
      <main className={styles.clientMainContent}>
        {/* HAMILTON GALLERY */}
        {/* Desktop */}
        <article className={styles.clientPortfolioItem}>
          <h3 className={styles.imageHeading}>DESKTOP</h3>          
          <a
          href="https://www.hamiltongallery.co.za/" 
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles.portfolioImage}>
            <img
              src="/media/websites/hamilton-gallery-dtop.png"
              alt="hamilton-gallery"
              className="thumbnail"
            />
          </div>
          </a>
          {/* Mobile */}
          <p>Hamilton Gallery</p>
        </article>
        <article className={styles.clientPortfolioItem}>
          <h3 className={styles.portfolioImage}>MOBILE</h3>
          <a
          href="https://www.hamiltongallery.co.za/" 
          target="_blank"
          rel="noopener noreferrer"
        > 
          <div className={styles.portfolioImageMobile}>
            <img
              src="/media/websites/hamilton-gallery-mobile.png"
              alt="hamilton-gallery"
              className="thumbnail"
            />
          </div>
          </a>
          <p>Hamilton Gallery</p>
        </article>
        {/* OCTOHOODIE */}
        {/* DESKTOP */}
        <article className={styles.clientPortfolioItem}>
          <h3 className={styles.imageHeading}>DESKTOP</h3>
          <a
          href="https://www.octohoodie.com/" 
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles.portfolioImage}>
            <img src="/media/websites/octohoodie_dtop.png" alt="octohoodie" />
          </div>
          <p>Octohoodie</p>
          </a>
        </article>
        {/* MOBILE */}
        <article className={styles.clientPortfolioItem}>
          <h3 className={styles.portfolioImage}>MOBILE</h3>
          <a
          href="https://www.octohoodie.com/" 
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles.portfolioImageMobile}>
            <img src="/media/websites/octohoodie_mobile.png" alt="octohoodie" />
          </div>
          </a>   
          <p>Octohoodie</p>
  
        </article>
        {/* ECLECTIC FACILITATORS */}
        {/* DESKTOP */}
        <article className={styles.clientPortfolioItem}>
          <h3 className={styles.imageHeading}>DESKTOP</h3>
          <a
          href="https://eclecticfacilitators.co.za/" 
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles.portfolioImage}>
            <img
              src="/media/websites/eclectic_facilitators_dtop.png"
              alt="eclectic_facilitators"
            />
          </div>
          </a>
          <p>Eclectic Facilitators</p>
        </article>
        {/* MOBILE */}
        <article className={styles.clientPortfolioItem}>
          <h3 className={styles.portfolioImage}>MOBILE</h3>
          <a
          href="https://eclecticfacilitators.co.za/" 
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles.portfolioImageMobile}>
            <img
              src="/media/websites/eclectic_facilitators_mobile.png"
              alt="eclectic_facilitators"
            />
          </div>
          </a>
          <p>Eclectic Facilitators</p>
        </article>
        {/* CLINTON GAHWILER */}
        {/* DESKTOP */}
        <article className={styles.clientPortfolioItem}>
          <h3 className={styles.imageHeading}>DESKTOP</h3>
          <a
          href="https://www.clintongahwiler.com/" 
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles.portfolioImage}>
            <img
              src="/media/websites/clinton-gahwiler_dtop.png"
              alt="clinton-gahwiler"
            />
          </div>
          </a>
          <p>Clinton Gahwiler</p>
        </article>
        {/* MOBILE */}
        <article className={styles.clientPortfolioItem}>
          <h3 className={styles.portfolioImage}>MOBILE</h3>
          <a
          href="https://www.clintongahwiler.com/" 
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles.portfolioImageMobile}>
            <img
              src="/media/websites/clinton-gahwiler_mobile.png"
              alt="clinton-gahwiler"
            />
          </div>
          </a>
          <p>Clinton Gahwiler</p>
        </article>
        {/* CAPE DJ CO */}
        {/* DESKTOP */}
        <article className={styles.clientPortfolioItem}>
          <h3 className={styles.imageHeading}>DESKTOP</h3>
          <a
          href="https://www.capedjco.co.za/" 
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles.portfolioImage}>
            <img src="/media/websites/cape_dj_co_dtop.png" alt="cape_dj_co" />
          </div>
          </a>
          <p>Cape DJ Co.</p>
        </article>
        {/* MOBILE */}
        <article className={styles.clientPortfolioItem}>
          <h3 className={styles.portfolioImage}>MOBILE</h3>
          <a
          href="https://www.capedjco.co.za/" 
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles.portfolioImageMobile}>
            <img src="/media/websites/cape_dj_co_mobile.png" alt="cape_dj_co" />
          </div>
          </a>
          <p>Cape DJ Co.</p>
        </article>
        {/* BONITAS FEMALE HEALTH */}
        {/* DESKTOP */}
        <article className={styles.clientPortfolioItem}>
          <h3 className={styles.imageHeading}>DESKTOP</h3>
          <a
          href="https://bonitasfemalehealth.co.za./" 
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles.portfolioImage}>
            <img
              src="/media/websites/bonitas_female_health_dtop.png"
              alt="bonitas_female_health"
            />
          </div>
          </a>
          <p>Bonitas Female Health</p>
        </article>
        {/* MOBILE */}
        <article className={styles.clientPortfolioItem}>
          <h3 className={styles.portfolioImage}>MOBILE</h3>
          <a
          href="https://bonitasfemalehealth.co.za./" 
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles.portfolioImageMobile}>
            <img
              src="/media/websites/bonitas_female_health_mobile.png"
              alt="bonitas_female_health"
            />
          </div>
          </a>
          <p>Bonitas Female Health</p>
        </article>
        {/* MACSTANLEY */}
        {/* DESKTOP */}
        <article className={styles.clientPortfolioItem}>
          <h3 className={styles.imageHeading}>DESKTOP</h3>
          <a
          href="https://macstanley.co.za/" 
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles.portfolioImage}>
            <img src="/media/websites/macstanley_dtop.png" alt="macstanley" />
          </div>
          </a>
          <p>Macstanley</p>
        </article>
        {/* MOBILE */}
        <article className={styles.clientPortfolioItem}>
          <h3 className={styles.portfolioImage}>MOBILE</h3>
          <a
          href="https://macstanley.co.za/" 
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles.portfolioImageMobile}>
            <img src="/media/websites/macstanley_mobile.png" alt="macstanley" />
          </div>
          </a>
          <p>Macstanley</p>
        </article>
        {/* BLACKSHEEP MUSIC */}
        {/* DESKTOP */}
        <article className={styles.clientPortfolioItem}>
          <h3 className={styles.imageHeading}>DESKTOP</h3>
          <a
          href="https://www.blacksheepmusic.co.za/" 
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles.portfolioImage}>
            <img src="/media/websites/blacksheep_music_dtop.png" alt="blacksheep_music" />
          </div>
          </a>
          <p>Blacksheep Music</p>
        </article>
        {/* MOBILE */}
        <article className={styles.clientPortfolioItem}>
          <h3 className={styles.portfolioImage}>MOBILE</h3>
          <a
          href="https://www.blacksheepmusic.co.za/" 
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles.portfolioImageMobile}>
            <img src="/media/websites/blacksheep_music_mobile.png" alt="blacksheep_music" />
          </div>
          </a>
          <p>Blacksheep Music</p>
        </article>

        {/* Add more portfolio items as needed */}
      </main>
    </div>
  );
};

export default DigitalBlendClients;
