import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function PasswordResetRequest() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const handlePasswordResetRequest = async (e) => {
    e.preventDefault();

    setError('');
    setMessage('');

    let bodyContent = JSON.stringify({ email });

    try {
      let response = await fetch('/api/email/forgot-password', {
        method: 'POST',
        body: bodyContent,
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('Password reset link has been sent to your email.');
        navigate('/go-to-mail')
      } else {
        setError(data.message || 'Failed to send password reset link.');
      }
    } catch (networkError) {
      setError('Failed to connect to the server.');
    }
  };

  return (
    <div className="container my-4">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title mb-4 text-center">Reset Password</h2>
              {error && <p className="text-danger">{error}</p>}
              {message && <p className="text-success">{message}</p>}
              <form onSubmit={handlePasswordResetRequest}>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Enter your email address:
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    required
                  />
                </div>
                <button type="submit" className="btn btn-primary w-100">
                  Send Reset Link
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordResetRequest;
